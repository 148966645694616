import {
  CheckOutlined,
  ClockCircleTwoTone,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
  TableOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  CardProps,
  Col,
  Dropdown,
  MenuProps,
  message,
  Popover,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ColumnsType, TableProps } from 'antd/lib/table';
import moment from 'moment';
import { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import UserContext from '../../contexts/user';
import {
  IGetTruckLoadsResponse,
  ITruckLoadsExpandableColumns,
} from '../../structures/interfaces/TruckLoad';
import { IGetLoginDataUser } from '../../structures/interfaces/User';
import { IGetCustomer } from '../../structures/interfaces/Clients';
import { IUseRequestAction, useRequest } from '../../hooks/useRequest';
import TruckLoadController from '../../structures/controllers/TruckLoad';

interface ITruckLoadsTableProps {
  cardProps?: CardProps;
  tableProps?: TableProps<IGetTruckLoadsResponse>;
  truckLoadStatus?: DefaultOptionType[];
  customerData?: IGetCustomer | null;
  userData?: IGetLoginDataUser | null;
}

const renderContent = (text?: string) => {
  if (text) return text;
  else return '---';
};

const RenderTableActions = (
  truckLoad: IGetTruckLoadsResponse,
  userData?: IGetLoginDataUser | null,
  filterByUpdate?: any[],
  filterByDelete?: any[],
  customerData?: IGetCustomer | null,
  cancelTruckLoad?: IUseRequestAction<
    {
      truck_load_id: string;
    },
    boolean
  >
) => {
  const history = useHistory();

  const updateTruckLoadPermission = filterByUpdate?.find(
    permission => permission?.userPermissions.module === 'CARGAS'
  );
  const deleteTruckLoadPermission = filterByDelete?.find(
    permission => permission?.userPermissions.module === 'CARGAS'
  );

  const items: MenuProps['items'] = [
    {
      key: 'view',
      label: 'Visualizar',
      icon: <EyeOutlined />,
      onClick: () =>
        history.push({
          pathname: `/cargas/${truckLoad.id}`,
          state: {
            truckLoadId: truckLoad.id,
            status: truckLoad.status,
          },
        }),
    },
  ];

  if (updateTruckLoadPermission || customerData) {
    if (
      truckLoad.status !== 'AUTHORIZED_LOAD' &&
      truckLoad.status !== 'LOADED' &&
      truckLoad.status !== 'EXPIRED' &&
      truckLoad.status !== 'CANCELED'
    ) {
      if (!truckLoad.driver) {
        items.push({
          key: 'setDriver',
          label: 'Informe o motorista',
          icon: <EditOutlined />,
          onClick: () =>
            history.push({
              pathname: `/cargas/${truckLoad.id}`,
              state: {
                truckLoadId: truckLoad.id,
                status: truckLoad.status,
              },
            }),
        });
      }
      items.push({
        key: 'edit',
        label: 'Editar',
        icon: <EditOutlined />,
        onClick: () =>
          history.push({
            pathname: `/cargas/${truckLoad.id}`,
            state: {
              truckLoadId: truckLoad.id,
              status: truckLoad.status,
            },
          }),
      });
    }
  }
  if (userData) {
    if (truckLoad.status === 'WAITING_RELEASE') {
      items.push({
        key: 'auth',
        label: 'Autorizar',
        icon: <CheckOutlined />,
        onClick: () =>
          history.push({
            pathname: `/cargas/${truckLoad.id}`,
            state: {
              truckLoadId: truckLoad.id,
              status: truckLoad.status,
            },
          }),
      });
    }
    if (truckLoad.status === 'AUTHORIZED_LOAD') {
      items.push({
        key: 'loadTruck',
        label: 'Carregar',
        icon: <TableOutlined />,
        onClick: () =>
          history.push({
            pathname: `/cargas/${truckLoad.id}`,
            state: {
              truckLoadId: truckLoad.id,
              status: truckLoad.status,
            },
          }),
      });
    }
  }
  if (
    deleteTruckLoadPermission &&
    truckLoad.status !== 'LOADED' &&
    truckLoad.status !== 'EXPIRED' &&
    truckLoad.status !== 'CANCELED' &&
    truckLoad.status !== 'AUTHORIZED_LOAD'
  ) {
    items.push({
      key: 'cancel',
      label: 'Cancelar agendamento',
      danger: true,
      icon: <CloseOutlined />,
      onClick: () =>
        cancelTruckLoad?.({ truck_load_id: truckLoad.id })
          .then(() => message.success('Agendamento cancelado com sucesso!'))
          .catch(() =>
            message.error('Não foi possível cancelar o agendamento')
          ),
    });
  }

  return (
    <Dropdown menu={{ items }} placement="top" trigger={['click']}>
      <EllipsisOutlined style={{ fontSize: 24 }} />
    </Dropdown>
  );
};

const TruckLoadsTable = ({
  cardProps,
  tableProps,
  customerData,
  userData,
}: ITruckLoadsTableProps): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const { filterByUpdate, filterByDelete } = useContext(UserContext);
  const [pdfDownloadRequest] = useRequest(TruckLoadController.pdfDownload);
  const [cancelTruckLoad, isLoading] = useRequest(
    TruckLoadController.cancelTruckLoad
  );
  const [loadsToDownload, setLoadsToDownload] = useState<string[]>([]);

  const pdfDownload = (truckLoadId: string) => {
    setLoadsToDownload(downloadingTruckLoadIds => [
      ...downloadingTruckLoadIds,
      truckLoadId,
    ]);

    const download = (fileUrl: string) => {
      const browserUrl = window.location.href.split('/');
      if (`/${browserUrl.pop()}` === '/cargas') {
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = `${fileUrl}`;
        a.click();
      }
    };

    pdfDownloadRequest({ truck_load_id: truckLoadId })
      .then(value => download(value))
      .catch(error =>
        message.error({
          content: t(error.message),
          style: { marginTop: '4rem' },
        })
      )
      .finally(() =>
        setLoadsToDownload(downloadingTruckLoadIds =>
          downloadingTruckLoadIds.filter(
            downloadingTruckLoadId => downloadingTruckLoadId !== truckLoadId
          )
        )
      );
  };

  const formLoadButton = (
    <Button
      children="Formar carga"
      type="primary"
      icon={<PlusOutlined />}
      onClick={() => history.push('/cargas/formar-carga')}
    />
  );

  const columns: ColumnsType<IGetTruckLoadsResponse> = [
    {
      title: 'Cliente',
      dataIndex: ['farmName'],
      key: 'name',
      render: (_, record) => {
        if (record.customers.length === 1) {
          return record.customers[0].farms[0].name;
        } else {
          return `${record.customers.length} Clientes`;
        }
      },
    },
    {
      title: 'O.C',
      dataIndex: ['loadingOrder', 'loadingOrder'],
      key: 'loadingOrder',
      render: (text, record) => {
        return record ? (
          <a
            style={{ marginLeft: 13 }}
            onClick={() => pdfDownload(record.id)}
            download
          >
            {text && record.loadingOrder ? (
              <Tooltip title={t('pages.truckLoads.downloadLOTooltip')}>
                <Button
                  type="primary"
                  shape="round"
                  ghost
                  loading={loadsToDownload.includes(record.id)}
                  icon={<DownloadOutlined />}
                >
                  {text}
                </Button>
              </Tooltip>
            ) : (
              <Button type="text" shape="round" disabled>
                ---
              </Button>
            )}
          </a>
        ) : null;
      },
    },
    {
      title: 'Nome da carga',
      dataIndex: ['name'],
      key: 'loadName',
      render: renderContent,
    },
    {
      title: 'Data agendada',
      dataIndex: ['cadence', 'startTime'],
      key: 'scheduleDate',
      render: (_, record) => {
        return moment(record.cadence.startTime).format('DD/MM/YYYY');
      },
    },
    {
      title: 'Veículo',
      dataIndex: ['vehicle', 'vehicleModel'],
      key: 'vehicle',
      render: renderContent,
    },
    {
      title: 'Peso estimado',
      dataIndex: ['estimatedWeight'],
      key: 'estimatedWeight',
      render: renderContent,
    },
    {
      title: 'Cargas carregadas',
      dataIndex: ['loadedAmount'],
      key: 'loadedLoads',
      render: renderContent,
    },
    {
      title: 'Motorista',
      dataIndex: ['driver', 'name'],
      key: 'driver',
      render: (driver, data) => {
        if (driver) return driver;
        if (!driver && data.status === 'CANCELED') return '---';
        if (!driver && data.status === 'EXPIRED') {
          const timeExpired = 'pages.truckLoads.expiredDriver';
          return (
            <Row gutter={6} style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Col>
                <Typography.Text type="secondary">
                  {t(`${timeExpired}.time`)}
                </Typography.Text>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography.Text type="secondary">
                  {t(`${timeExpired}.expired`)}
                </Typography.Text>
                &nbsp;&nbsp;
                <ClockCircleTwoTone twoToneColor="#C8C8C8" />
              </Col>
            </Row>
          );
        }
        if (data.status === 'WAITING_ACTIONS') return '---';
        return `${t('pages.truckLoads.setDriverUntil')} ${moment(
          data.expiresAt
        ).format('DD/MM/YYYY')} às ${moment(data.expiresAt).format('HH:mm')}`;
      },
    },
    {
      title: 'Status',
      dataIndex: ['status'],
      key: 'status',
      render: (_, record) => {
        switch (record.status) {
          case 'WAITING_DRIVER':
            return (
              <Tag color="orange">{t('pages.truckLoads.waitingDriver')}</Tag>
            );

          case 'WAITING_RELEASE':
            return (
              <Tag color="purple">{t('pages.truckLoads.waitingComercial')}</Tag>
            );

          case 'LOADED':
            return <Tag color="green">{t('pages.truckLoads.loaded')}</Tag>;

          case 'AUTHORIZED_LOAD':
            return <Tag color="blue">{t('pages.truckLoads.loadReleased')}</Tag>;

          case 'CANCELED':
            return <Tag color="red">{t('pages.truckLoads.canceled')}</Tag>;

          case 'EXPIRED':
            return <Tag color="default">{t('pages.truckLoads.expired')}</Tag>;

          case 'LOADING':
            return <Tag color="magenta">{t('pages.truckLoads.loading')}</Tag>;

          case 'WAITING_ACTIONS':
            return (
              <>
                {customerData ? (
                  <Tag color="purple">
                    {t('pages.truckLoads.waitingComercial')}
                  </Tag>
                ) : (
                  <div
                    style={{
                      cursor: 'grab',
                    }}
                  >
                    <Popover
                      content={record.cancellationReasons[0].message}
                      title={t('pages.truckLoads.refuseReason')}
                      trigger="click"
                    >
                      <Tag
                        color="orange"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.2rem',
                        }}
                      >
                        <ExclamationCircleOutlined />
                        {t(`pages.truckLoads.waitingActions`)}
                      </Tag>
                    </Popover>
                  </div>
                )}
              </>
            );
        }
      },
    },
    {
      title: 'Ações',
      dataIndex: ['actions'],
      key: 'actions',
      render: (_, record) =>
        RenderTableActions(
          record,
          userData,
          filterByUpdate,
          filterByDelete,
          customerData,
          cancelTruckLoad
        ),
    },
  ];

  const filteredColumns = columns.filter(col => col.key !== 'estimatedWeight');

  const expandedCustomersColumns: ColumnsType<ITruckLoadsExpandableColumns> = [
    {
      title: t('pages.truckLoads.farmName'),
      dataIndex: ['name'],
      key: 'nameExpandedRow',
      render: (_, record) => {
        return <b>{record.farms[0].name}</b>;
      },
    },
    {
      title: t('pages.truckLoads.loadedBags'),
      dataIndex: ['amountInTruckLoad'],
      key: 'amountInTruckLoadExpandedRow',
    },
  ];

  return (
    <Card title="Cargas cadastradas" extra={formLoadButton} {...cardProps}>
      <Table
        columns={filteredColumns}
        size="small"
        {...tableProps}
        expandable={{
          rowExpandable: record => {
            if (record.customers.length > 1) return true;
            return false;
          },
          expandedRowRender: record => {
            const customers = record.customers;
            customers.map(customer => {
              if (!customer.name) customer.name = customer.tradeName;
            });

            return (
              <Table
                columns={expandedCustomersColumns}
                dataSource={customers}
                pagination={false}
              />
            );
          },
        }}
      />
    </Card>
  );
};

export default TruckLoadsTable;
