import { Col, Divider, Form, Input, Select, message } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { Rule } from 'antd/lib/form';
import { BaseSelectRef } from 'rc-select';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import Formatter from '../../../classes/Formatter';
import Normalizer from '../../../classes/Normalizer';
import Validator from '../../../classes/Validator';
import { useRequest } from '../../../hooks/useRequest';
import TruckLoadController from '../../../structures/controllers/TruckLoad';
import {
  INewShippingCompany,
  IRegisterShippingCompany,
  IUpdateShippingCompany,
} from '../../../structures/interfaces/Company/Company';
import {
  IFilterShippingCompanies,
  IGetVehicles,
  IStepOneData,
  IViewTruckLoadDriver,
} from '../../../structures/interfaces/TruckLoad';
import RegisterShippingCompany from './RegisterShippingCompany';

const { Option } = Select;

interface IDriverFields {
  t: TFunction;
  shippingCompanies: INewShippingCompany[];
  hasAdded?: boolean;
  setHasAdded?: Dispatch<SetStateAction<boolean>>;
  vehicles: IGetVehicles[];
  dontHaveCarrier: boolean;
  initialValues?: IViewTruckLoadDriver;
  setInitialValues?: Dispatch<SetStateAction<IViewTruckLoadDriver | undefined>>;
  form?: FormInstance;
  setSelectedDriverVehicle?: Dispatch<SetStateAction<string | undefined>>;
  selectRef?: MutableRefObject<BaseSelectRef | null>;
  openModal?: boolean;
  setOpenModal?: Dispatch<boolean>;
  isWrongVehicle?: boolean;
  setIsWrongVehicle?: Dispatch<SetStateAction<boolean>>;
  stepOneData?: IStepOneData;
  isDriverEditing?: boolean;
  // shipping company fields
  createShippingCompany?: IRegisterShippingCompany;
  setCreateShippingCompany?: Dispatch<
    SetStateAction<IRegisterShippingCompany | undefined>
  >;
  updateShippingCompany?: IRegisterShippingCompany;
  setUpdateShippingCompany?: Dispatch<
    SetStateAction<IUpdateShippingCompany | undefined>
  >;
}

const { Search } = Input;

function DriverFields({
  t,
  hasAdded,
  setHasAdded,
  vehicles,
  initialValues,
  setInitialValues,
  form,
  setSelectedDriverVehicle,
  selectRef,
  openModal,
  setOpenModal,
  setIsWrongVehicle,
  stepOneData,
  isDriverEditing,
  dontHaveCarrier,
}: IDriverFields) {
  const [filteredShippingCompanies, setFilteredShippingCompanies] = useState<
    IFilterShippingCompanies[]
  >([]);
  const [selectedShippingCompany, setSelectedShippingCompany] =
    useState<IFilterShippingCompanies>();
  const [isFetched, setIsFetched] = useState(false);
  const [isCarrierFieldsDisabled, setIsCarrierFieldsDisabled] = useState(false);
  const [newShippingCompany, setNewShippingCompany] =
    useState<INewShippingCompany>();
  const [filterShippingCompanies] = useRequest(
    TruckLoadController.filterShippingCompanies
  );
  const [findShippingCompanyRequest] = useRequest(
    TruckLoadController.findShippingCompany
  );

  const [filterDriverRequest] = useRequest(TruckLoadController.findDriver);

  const baseString = 'pages.truckLoads.createLoad';
  const baseStringShippingCompany =
    'pages.truckLoads.createLoad.stepThree.shippingCompany';

  const validPhoneNumber = (rule: Rule, value: string) => {
    if (!value) return Promise.resolve();
    if (Validator.validatePhoneNumber(value)) return Promise.resolve();
    return Promise.reject(
      new Error(t(`${baseString}.validData.invalidCellphone`))
    );
  };
  const validCPF = (rule: Rule, value: string) => {
    if (Validator.validateCPF(value)) return Promise.resolve();
    return Promise.reject(new Error(t(`${baseString}.validData.invalidCpf`)));
  };

  const validCNPJ = (rule: Rule, value: string) => {
    if (Validator.validateCNPJ(value)) return Promise.resolve();
    return Promise.reject(new Error(t(`${baseString}.validData.invalidCnpj`)));
  };
  const validEmail = (rule: Rule, value: string) => {
    if (!value) return Promise.resolve();
    if (!value || Validator.validateEmail(value)) return Promise.resolve();
    return Promise.reject(new Error(t(`${baseString}.validData.invalidEmail`)));
  };
  const validLicensePlate = (rule: Rule, value: string) => {
    const regexPlacaPadraoAntigo = /^[a-zA-Z]{3}[0-9]{4}$/i;
    const regexPlacaPadraoMercosul = /^[a-zA-Z]{3}[0-9][a-zA-Z][0-9]{2}$/i;
    const placaFormatada = Normalizer.onlyNumbersLetters(value);
    if (regexPlacaPadraoAntigo.test(placaFormatada)) {
      return Promise.resolve();
    } else if (regexPlacaPadraoMercosul.test(placaFormatada)) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error(t(`${baseString}.validData.invalidLicensePlate`))
    );
  };
  const requiredRule = {
    required: true,
    message: t('pages.settings.pickUpLocations.requiredRuleMessage'),
  };
  const showError = () => {
    message.error({
      content: t(`${baseStringShippingCompany}.createError`),
      style: { marginTop: '4rem' },
    });
  };

  const showErrorShippingCompany = () => {
    message.error({
      content: t(`${baseStringShippingCompany}.shippingCompanyNotFound`),
      style: { marginTop: '3rem' },
    });
  };

  const showErrorFindDriver = () => {
    message.error({
      content: t(`${baseStringShippingCompany}.driverNotFound`),
      style: { marginTop: '3rem' },
    });
  };

  useEffect(() => {
    if (form && selectedShippingCompany) {
      form.setFieldsValue({
        shippingCompanyId: selectedShippingCompany.shippingCompanyId,
        shippingCompanyTradeName:
          selectedShippingCompany.shippingCompanyTradeName,
        shippingCompanyEmail: selectedShippingCompany.shippingCompanyEmail,
        shippingCompanyPhoneNumber: Formatter.formatPhoneWithoutDDI(
          selectedShippingCompany.shippingCompanyPhoneNumber
        ),
        shippingCompanyName: selectedShippingCompany.shippingCompanyName,
      });
    }
  }, [filteredShippingCompanies, selectedShippingCompany]);

  useEffect(() => {
    if (isDriverEditing && initialValues?.shippingCompany?.id) {
      setIsCarrierFieldsDisabled(false);
    }
    if (
      !initialValues?.shippingCompany?.id &&
      !form?.getFieldValue('shippingCompanyId')
    ) {
      setIsCarrierFieldsDisabled(true);
    }
  }, [isDriverEditing]);

  const shippingCompanyFields = !dontHaveCarrier && (
    <>
      <Form.Item
        name="shippingCompanyId"
        initialValue={initialValues?.shippingCompany?.id}
      >
        <Input hidden />
      </Form.Item>
      <Col sm={24} md={12} lg={6} xl={6} xxl={5}>
        <Form.Item
          validateFirst
          name="shippingCompanyCnpj"
          label={t(`${baseStringShippingCompany}.labels.cnpj`)}
          normalize={Formatter.formatCNPJ}
          rules={[{ validator: validCNPJ }]}
          initialValue={initialValues?.shippingCompany?.documentNumberCnpj}
        >
          <Search
            placeholder={t(`${baseStringShippingCompany}.placeholders.cnpj`)}
            onSearch={value => {
              form?.resetFields([
                'shippingCompanyId',
                'shippingCompanyName',
                'shippingCompanyEmail',
                'shippingCompanyTradeName',
                'shippingCompanyPhoneNumber',
              ]);
              setIsFetched(!isFetched);
              const cnpj = value.replace(/[^0-9]/g, '');
              filterShippingCompanies({ cnpj })
                .then(res => {
                  setFilteredShippingCompanies(res);
                  setIsCarrierFieldsDisabled(false);
                  if (form) {
                    form.setFieldsValue({
                      shippingCompanyId: res[0].shippingCompanyId,
                      shippingCompanyName: res[0].shippingCompanyName,
                      shippingCompanyEmail: res[0].shippingCompanyEmail,
                      shippingCompanyPhoneNumber:
                        Formatter.formatPhoneWithoutDDI(
                          res[0].shippingCompanyPhoneNumber
                        ),
                      shippingCompanyTradeName: res[0].shippingCompanyTradeName,
                    });
                  }
                })
                .catch(() => {
                  if (form) {
                    if (initialValues) {
                      // initialValues.shippingCompany = undefined;
                      // initialValues.shippingCompanyId = undefined;
                      // form.resetFields();
                    }

                    form.resetFields([
                      'shippingCompanyId',
                      'shippingCompanyName',
                      'shippingCompanyEmail',
                      'shippingCompanyTradeName',
                      'shippingCompanyPhoneNumber',
                    ]);
                    setOpenModal?.(true);

                    // setIsCarrierFieldsDisabled(true);
                    showErrorShippingCompany();
                  }
                });
            }}
          />
        </Form.Item>
      </Col>
      <Col sm={24} md={12} lg={6} xl={6} xxl={9}>
        <Form.Item
          validateFirst
          dependencies={['shippingCompanyCnpj']}
          name="shippingCompanyName"
          label={t(`${baseStringShippingCompany}.labels.companyName`)}
          initialValue={initialValues?.shippingCompany?.companyName}
        >
          <Select
            placeholder={t(
              `${baseStringShippingCompany}.placeholders.companyName`
            )}
            allowClear
            onChange={value => {
              return findShippingCompanyRequest({
                shippingCompanyId: value,
              }).then(res => setSelectedShippingCompany(res[0]));
            }}
            showSearch
            optionFilterProp={'children'}
            disabled={isCarrierFieldsDisabled}
          >
            {filteredShippingCompanies.map(shipComp => {
              return (
                <Option
                  key={shipComp.shippingCompanyId}
                  value={shipComp.shippingCompanyId}
                >
                  {shipComp.shippingCompanyName ||
                    shipComp.shippingCompanyTradeName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>
      <Col sm={24} md={12} lg={6} xl={6} xxl={5}>
        <Form.Item
          validateFirst
          name="shippingCompanyTradeName"
          label={t(`${baseStringShippingCompany}.labels.tradeName`)}
          initialValue={initialValues?.shippingCompany?.tradeName}
        >
          <Input
            disabled={isCarrierFieldsDisabled}
            placeholder={t(
              `${baseStringShippingCompany}.placeholders.tradeName`
            )}
          />
        </Form.Item>
      </Col>
      <Col sm={24} md={12} lg={6} xl={6} xxl={5}>
        <Form.Item
          validateFirst
          name="shippingCompanyEmail"
          label={t(`${baseStringShippingCompany}.labels.email`)}
          initialValue={initialValues?.shippingCompany?.email}
        >
          <Input
            disabled={isCarrierFieldsDisabled}
            placeholder={t(`${baseStringShippingCompany}.placeholders.email`)}
          />
        </Form.Item>
      </Col>
      <Col sm={24} md={12} lg={6} xl={6} xxl={5}>
        <Form.Item
          validateFirst
          normalize={Formatter.formatCellphone}
          name="shippingCompanyPhoneNumber"
          label={t(`${baseStringShippingCompany}.labels.phoneNumber`)}
          initialValue={initialValues?.shippingCompany?.phoneNumber}
        >
          <Input
            disabled={isCarrierFieldsDisabled}
            placeholder={t(
              `${baseStringShippingCompany}.placeholders.phoneNumber`
            )}
          />
        </Form.Item>
      </Col>
    </>
  );

  const driverFields = (
    <>
      <Col sm={24} md={12} lg={6} xl={6} xxl={5}>
        <Form.Item
          name="driverDocument"
          label={t(`${baseString}.stepThree.labels.driverDocument`)}
          rules={[
            {
              ...requiredRule,
              validator: validCPF,
            },
          ]}
          required
          normalize={Formatter.formatCPF}
          initialValue={initialValues?.cpf}
        >
          <Search
            onSearch={value => {
              const cpf = value.replace(/[^0-9]/g, '');
              filterDriverRequest({ documentNumberCpf: cpf })
                .then(res => {
                  if (res.vehicleId !== stepOneData?.vehicle.id) {
                    setIsWrongVehicle?.(true);
                  } else {
                    setIsWrongVehicle?.(false);
                  }
                  if (form) {
                    const formatedData = {
                      ...res,
                      vehicle: { value: res.vehicleId, label: res.vehicle },
                      phoneNumber: Formatter.formatPhoneWithoutDDI(
                        res.phoneNumber
                      ),
                    };
                    setSelectedDriverVehicle?.(res.vehicleId);
                    form.setFieldsValue(formatedData);
                  }
                })
                .catch(() => {
                  if (form) {
                    form.resetFields([
                      'driver',
                      'vehicle',
                      'phoneNumber',
                      'licensePlate',
                      'email',
                      'driverObservation',
                    ]);
                    showErrorFindDriver();
                    setInitialValues?.(undefined);
                  }
                });
            }}
            placeholder={t(
              `${baseString}.stepThree.placeholders.driverDocument`
            )}
          />
        </Form.Item>
      </Col>
      <Col sm={24} md={12} lg={6} xl={6} xxl={5}>
        <Form.Item
          name="driver"
          rules={[{ ...requiredRule }]}
          label={t(`${baseString}.stepThree.labels.driver`)}
          required
          initialValue={initialValues?.name}
        >
          <Input
            placeholder={t(`${baseString}.stepThree.placeholders.driver`)}
          />
        </Form.Item>
      </Col>
      <Col sm={24} md={12} lg={6} xl={6} xxl={5}>
        <Form.Item
          name="vehicle"
          label={t(`${baseString}.stepThree.labels.vehicle`)}
          rules={[{ ...requiredRule }]}
          required
          initialValue={initialValues?.vehicleId}
        >
          <Select
            onChange={value => {
              setSelectedDriverVehicle && setSelectedDriverVehicle(value);
              if (value !== stepOneData?.vehicle.id) {
                setIsWrongVehicle?.(true);
              } else {
                setIsWrongVehicle?.(false);
              }
            }}
            placeholder={t(`${baseString}.stepThree.placeholders.select`)}
            listHeight={150}
          >
            {vehicles.map(vehicle => (
              <Option key={vehicle.id} value={vehicle.id}>
                {vehicle.vehicleModel}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col sm={24} md={12} lg={6} xl={6} xxl={5}>
        <Form.Item
          name="phoneNumber"
          label={t(`${baseString}.stepThree.labels.phoneNumber`)}
          normalize={Formatter.formatPhoneWithoutDDI}
          rules={[{ validator: validPhoneNumber }]}
          initialValue={initialValues?.phoneNumber}
        >
          <Input
            placeholder={t(`${baseString}.stepThree.placeholders.phoneNumber`)}
          />
        </Form.Item>
      </Col>
      <Col sm={24} md={12} lg={6} xl={4} xxl={4}>
        <Form.Item
          validateFirst
          name="licensePlate"
          label={t(`${baseString}.stepThree.labels.licensePlate`)}
          rules={[{ ...requiredRule }, { validator: validLicensePlate }]}
          required
          normalize={Normalizer.onlyNumbersLetters}
          initialValue={initialValues?.licensePlate}
        >
          <Input
            maxLength={8}
            placeholder={t(`${baseString}.stepThree.placeholders.licensePlate`)}
          />
        </Form.Item>
      </Col>
      <Col sm={24} md={12} lg={6} xl={6} xxl={5}>
        <Form.Item
          name="email"
          label={t(`${baseString}.stepThree.labels.email`)}
          rules={[{ validator: validEmail }]}
          initialValue={initialValues?.email}
        >
          <Input
            placeholder={t(`${baseString}.stepThree.placeholders.email`)}
          />
        </Form.Item>
      </Col>
      <Col sm={24} md={12} lg={8} xl={8} xxl={5}>
        <Form.Item
          name="driverObservation"
          label={t(`${baseString}.stepThree.labels.driverObservation`)}
          initialValue={initialValues?.note}
        >
          <Input
            placeholder={t(
              `${baseString}.stepThree.placeholders.driverObservation`
            )}
          />
        </Form.Item>
      </Col>
    </>
  );

  useEffect(() => {
    if (newShippingCompany) {
      filterShippingCompanies({
        cnpj: newShippingCompany.documentNumberCnpj,
      }).then(res => {
        setFilteredShippingCompanies(res);
      });
      form?.setFieldsValue({
        shippingCompanyId: newShippingCompany.id,
        shippingCompanyCnpj: Formatter.formatCNPJ(
          newShippingCompany.documentNumberCnpj
        ),
        shippingCompanyName: newShippingCompany.companyName,
        shippingCompanyEmail: newShippingCompany.email,
        shippingCompanyPhoneNumber: Formatter.formatPhoneWithoutDDI(
          newShippingCompany.phoneNumber
        ),
        shippingCompanyTradeName: newShippingCompany.tradeName,
      });
      setIsCarrierFieldsDisabled(false);
    }
  }, [newShippingCompany]);

  return (
    <>
      {shippingCompanyFields}
      {!dontHaveCarrier && <Divider />}
      {driverFields}

      <RegisterShippingCompany
        open={openModal!}
        setOpenModal={setOpenModal}
        hasAdded={hasAdded}
        setHasAdded={setHasAdded}
        setNewShippingCompany={setNewShippingCompany}
      />
    </>
  );
}

export default withTranslation()(DriverFields);
