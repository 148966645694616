import moment from 'moment';
import client from '../../api/urql/customClient';
import Formatter from '../../classes/Formatter';
import { INewShippingCompany } from '../interfaces/Company/Company';
import {
  IDriverForm,
  IFilterShippingCompanies,
  IGetCadences,
  IGetContractItems,
  IGetPreSchedule,
  IGetTruckLoadsResponse,
  IGetTruckLoadWhere,
  IGetVehicles,
  IUpdateTruckLoadInput,
  IUpdateTruckLoadWithTruckLoadItemsInput,
  IViewTruckLoad,
  truckLoadItem,
} from '../interfaces/TruckLoad';
import TruckLoadQueries from '../queries/TruckLoad';

const buildSlug = (truckLoads: IGetTruckLoadsResponse[]) => {
  return truckLoads?.map(truckLoad => ({
    ...truckLoad,
    slug: Formatter.removeAccents(
      `
        ${truckLoad.customers.map(customer => customer.name ?? '')}
        ${truckLoad.customers.map(customer => customer.tradeName ?? '')}
        ${truckLoad.loadingOrder?.loadingOrder ?? ''}
        ${truckLoad.name ?? ''}
        ${truckLoad.cadence.startTime ?? ''}
        ${truckLoad.vehicle.vehicleModel ?? ''}
        ${truckLoad.allocatedAmount ?? ''}
        ${truckLoad.estimatedWeight ?? ''}
        ${truckLoad.loadedAmount ?? ''}
        ${truckLoad?.driver?.name ?? ''}
      `.toLowerCase()
    ),
  }));
};

const filterTruckLoadByStatus = (
  truckLoads: IGetTruckLoadsResponse[],
  truckLoadStatus?: string
) => {
  return truckLoadStatus != undefined
    ? truckLoads.filter(truckLoad => {
        return truckLoad.status === truckLoadStatus;
      })
    : truckLoads;
};

const filterTruckLoadByPickUpLocation = (
  truckLoad: IGetTruckLoadsResponse[],
  truckLoadPickUpLocation?: string
) => {
  if (truckLoadPickUpLocation != undefined) {
    return truckLoad.filter(truckLoad => {
      return truckLoad.pickUpLocation.id === truckLoadPickUpLocation;
    });
  } else {
    return truckLoad;
  }
};
const filterTruckLoadByDate = (
  truckLoads: IGetTruckLoadsResponse[],
  date?: [moment.Moment, moment.Moment]
) =>
  date != undefined
    ? truckLoads.filter(truckLoad => {
        return moment(truckLoad.cadence.startTime).isBetween(
          date[0],
          date[1],
          'day',
          '[]'
        );
      })
    : truckLoads;

class TruckLoadController {
  static getVehicles = ({
    organizationId,
    takeCommonVehicles,
  }: {
    organizationId: string;
    takeCommonVehicles: boolean;
  }): Promise<IGetVehicles[]> =>
    new Promise((resolve, reject) =>
      client
        .query(TruckLoadQueries.getVehicles(), {
          where: {
            organization_id: organizationId,
            take_common_vehicles: takeCommonVehicles,
          },
        })
        .then(({ getVehicles }) => {
          resolve(getVehicles);
        })
        .catch(err => reject(err))
    );

  static getCadences = ({
    seedType,
    pickupLocation,
    // unityWeight,
    unity,
  }: {
    seedType: string;
    pickupLocation: string;
    // unityWeight?: number;
    unity: string;
  }): Promise<IGetCadences[]> =>
    new Promise((resolve, reject) =>
      client
        .query(TruckLoadQueries.getCadences(), {
          where: {
            seed_type: seedType,
            pick_up_location: pickupLocation,
            // unity_weight: unityWeight,
            unity: unity,
          },
        })
        .then(({ getCadences }) => {
          const formattedCadences: IGetCadences[] = getCadences.map(
            ({ ...getCadences }) => {
              return {
                ...getCadences,
                startTime: moment(getCadences.startTime),
                endTime: moment(getCadences.endTime),
              };
            }
          );
          resolve(formattedCadences);
        })
        .catch(reject)
    );

  static createPreSchedule = ({
    createdBy,
    cadenceId,
    pickupLocation,
    vehicle,
    farmId,
  }: {
    createdBy: string;
    cadenceId: string;
    pickupLocation: string;
    vehicle: string;
    farmId: string;
  }): Promise<IGetPreSchedule> => {
    const inputs = {
      created_by: createdBy,
      cadence_id: cadenceId,
      pick_up_location_id: pickupLocation,
      vehicle_id: vehicle,
      farm_id: farmId,
    };

    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadQueries.createPreSchedule(), {
          input: inputs,
        })
        .then(({ createPreSchedule }) => {
          resolve(createPreSchedule);
        })
        .catch(reject)
    );
  };

  static cancelPreSchedule = ({
    id,
  }: {
    id: string;
  }): Promise<{ success: boolean }> => {
    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadQueries.cancelPreSchedule(), {
          where: { id },
        })
        .then(({ cancelPreSchedule }) => {
          resolve(cancelPreSchedule);
        })
        .catch(reject)
    );
  };

  static getContractItems = ({
    seedType,
    unity,
    organizationId,
    prescheduleId,
    excludeTsi,
  }: {
    seedType: string;
    unity: string;
    organizationId?: string;
    prescheduleId: string;
    excludeTsi?: boolean;
  }): Promise<{
    contractItems: IGetContractItems[];
    customerRemainingAmount?: number;
  }> =>
    new Promise((resolve, reject) =>
      client
        .query(TruckLoadQueries.getContractItems(), {
          where: {
            seed_type_id: seedType,
            organization_id: organizationId,
            unity: unity,
            pre_schedule_id: prescheduleId,
            exclude_tsi: excludeTsi,
          },
        })
        .then(({ getContractItems }) => {
          const formattedContractItems: IGetContractItems[] =
            getContractItems.contractItems.map((item, index) => {
              const { name } = item.contract.farm;
              return {
                ...item,
                customerId: item.contract.farm.customerId,
                client: name,
                contractNumber: item.contract.referenceNumber,
                stateRegistration: item.contract.farm.stateRegistration,
                index,
              };
            });
          resolve({
            contractItems: formattedContractItems,
            customerRemainingAmount: getContractItems.customerCapacity
              ? getContractItems.customerCapacity.remaingAmount
              : undefined,
          });
        })
        .catch(err => reject(err))
    );

  static fetchLoads = (
    filters?: IGetTruckLoadWhere
  ): Promise<IGetTruckLoadsResponse[]> =>
    new Promise((resolve, reject) =>
      client
        .query(TruckLoadQueries.getOrganizationTruckLoads(), {
          where: {
            organization_id: filters?.organization_id,
            subordinates_filter:
              filters?.subordinates_filter === null
                ? null
                : {
                    ignore_subordinates:
                      filters?.subordinates_filter?.ignore_subordinates,
                    subordinates_only:
                      filters?.subordinates_filter?.subordinates_only,
                    exclude_subordinates:
                      filters?.subordinates_filter?.exclude_subordinates,
                    include_subordinates:
                      filters?.subordinates_filter?.include_subordinates,
                    take_only: filters?.subordinates_filter?.take_only,
                  },
          },
        })
        .then(({ getTruckLoads }) => {
          const filteredTruckLoads = getTruckLoads.filter(
            tl => !!tl.customers.length
          );
          const formatedData = filteredTruckLoads.map(item => {
            if (item.customers.length > 1) {
              item.customerName = 'Clientes: ' + item.customers.length;
            } else {
              item.customerName = item.customers[0].name
                ? (item.customerName = item.customers[0].name)
                : item.customers[0].tradeName;
            }

            return {
              ...item,
              startTimeString: moment(item.cadence.startTime).format(
                'DD/MM/YYYY'
              ),
            };
          });

          resolve(formatedData);
        })
        .catch(err => reject(err))
    );
  static getShippingCompanies = (): Promise<INewShippingCompany[]> =>
    new Promise((resolve, reject) =>
      client
        .query(TruckLoadQueries.getShippingCompanies())
        .then(({ getShippingCompanies }) => {
          resolve(getShippingCompanies);
        })
        .catch(err => reject(err))
    );

  static getCustomerTruckLoads = (): Promise<IGetTruckLoadsResponse[]> =>
    new Promise((resolve, reject) =>
      client
        .query(TruckLoadQueries.getCustomerTruckLoads(), {
          where: {},
        })
        .then(({ getTruckLoads }) => {
          const formatedData = getTruckLoads.map(item => {
            if (item.customers.length > 1) {
              item.customerName = 'Clientes: ' + item.customers.length;
            } else {
              item.customerName = item.customers[0].name
                ? (item.customerName = item.customers[0].name)
                : item.customers[0].tradeName;
            }
            return {
              ...item,
              startTimeString: moment(item.cadence.startTime).format(
                'DD/MM/YYYY'
              ),
            };
          });
          resolve(formatedData);
        })
        .catch(err => reject(err))
    );

  static filterTruckLoad = (
    getTruckLoads: IGetTruckLoadsResponse[],
    {
      search = '',
      truckLoadStatus,
      truckLoadDate,
      truckLoadPickUpLocation,
    }: {
      search?: string;
      truckLoadStatus?: string;
      truckLoadDate?: [moment.Moment, moment.Moment];
      truckLoadPickUpLocation?: string;
    }
  ): IGetTruckLoadsResponse[] => {
    return filterTruckLoadByDate(
      filterTruckLoadByPickUpLocation(
        filterTruckLoadByStatus(
          buildSlug(getTruckLoads).filter(truckLoad => {
            return truckLoad.slug.includes(
              Formatter.removeAccents(search.toLowerCase().trim())
            );
          }),
          truckLoadStatus
        ),
        truckLoadPickUpLocation
      ),
      truckLoadDate
    );
  };
  static createTruckLoad = ({
    organizationId,
    preScheduleId,
    name,
    loadNote,
    items,
    driver,
    shipping_company_id,
    shipping_company_data: shippingCompanyData,
    referenceVehicle,
    driverVehicle,
    estimated_weight,
    isCustomOrdering,
    noShippingCompany,
  }: {
    organizationId: string;
    preScheduleId: string;
    name: string;
    loadNote: string;
    items: truckLoadItem[];
    driver?: {
      name: string;
      document_number_cpf: string;
      vehicle_license_plate: string;
      note?: string;
      phone_number?: string;
      email?: string;
      shipping_company_id?: string | null;
    };
    shipping_company_id?: string | null;
    shipping_company_data?: {
      company_name: string;
      trade_name: string;
      document_number_cnpj: string;
      phone_number: string;
      email: string;
    } | null;
    referenceVehicle: string;
    driverVehicle?: string;
    estimated_weight: number;
    isCustomOrdering: boolean;
    noShippingCompany?: boolean;
  }): Promise<{
    id: string;
  }> => {
    const inputs = {
      pre_schedule_id: preScheduleId,
      organization_id: organizationId,
      vehicle_id: driverVehicle || referenceVehicle,
      estimated_weight,
      name: name,
      load_note: loadNote,
      reference_vehicle_id: referenceVehicle,
      truck_load_items: items,
      create_driver: driver,
      shipping_company_id,
      shipping_company_data: shippingCompanyData,
      can_sort_truck_load_item: isCustomOrdering,
      no_shipping_company: noShippingCompany,
    };

    return new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadQueries.createTruckLoad(), {
          input: inputs,
        })
        .then(({ formTruckLoad }) => {
          resolve(formTruckLoad);
        })
        .catch(reject)
    );
  };

  static getTruckLoad = ({
    truckLoadId,
  }: {
    truckLoadId: string;
  }): Promise<IViewTruckLoad> =>
    new Promise((resolve, reject) =>
      client
        .query(TruckLoadQueries.getTruckLoad(), {
          where: {
            truck_load_id: truckLoadId,
          },
        })
        .then(({ getTruckLoad }) => {
          getTruckLoad.truck_load_items = getTruckLoad.truck_load_items.sort(
            (a, b) => a.load_sort - b.load_sort
          );

          const formattedTruckLoadItems = getTruckLoad.truck_load_items.map(
            item => ({
              id: item.id,
              contractItemId: item.contract_item.id,
              client:
                item.contract_item.contract.farm.customer.companyName ||
                item.contract_item.contract.farm.customer.tradeName ||
                item.contract_item.contract.farm.customer.name,
              stateRegistration:
                item.contract_item.contract.farm.stateRegistration,
              contractNumber: item.contract_item.contract.referenceNumber,
              contractDate: item.contract_item.contract.contractDate,
              referenceNumber: item.contract_item.reference_number,
              cultivation: item.contract_item.cultivation,
              sieve: item.contract_item.sieve,
              category: item.contract_item.sieve,
              packing: item.contract_item.packing,
              tsi: item.contract_item.tsi,
              estimatedWeight: item.contract_item.estimated_weight,
              allocatedAmount: item.allocated_amount,
              loadedAmount: item.loaded_amount,
              customerId: item.customer_id,
            })
          );

          const {
            name,
            vehicle: { id, vehicleModel, bagsCapacity, weightCapacity },
            pick_up_location: { title: pickupLocation },
            cadence: {
              rule: {
                capacityPerCompany,
                unity,
                seed_type: { name: seedType },
              },
              start_time: selectedDate,
            },
            status,
            load_note: observation,
            estimated_weight: estimatedWeight,
          } = getTruckLoad;

          const description = {
            name,
            vehicleId: id,
            vehicleModel,
            vehicleBagsCapacity: bagsCapacity,
            vehicleWeightCapacity: weightCapacity,
            capacityPerCompany,
            unity,
            seedType,
            pickupLocation,
            selectedDate,
            status,
            estimatedWeight,
            observation,
          };

          resolve({
            ...getTruckLoad,
            id: getTruckLoad.id,
            description,
            items: formattedTruckLoadItems,
            driver: getTruckLoad.driver,
            status: getTruckLoad.status,
            shippingCompany: getTruckLoad.shippingCompany,
            preScheduleId: getTruckLoad.pre_schedule_id,
            authorization: getTruckLoad.authorization,
            manager:
              getTruckLoad.customerManager?.id || getTruckLoad.userManager?.id,
            cancellationReasons: getTruckLoad.cancellationReasons,
          });
        })
        .catch(err => reject(err))
    );

  static updateTruckLoad = ({
    truckLoadId,
    input,
  }: {
    truckLoadId: string;
    input: IUpdateTruckLoadInput;
  }): Promise<{ id: string }> =>
    new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadQueries.updateTruckLoad(), {
          where: {
            truck_load_id: truckLoadId,
          },
          input,
        })
        .then(({ updateTruckLoad }) => {
          resolve(updateTruckLoad);
        })
        .catch(err => {
          reject(err);
        })
    );

  static UpdateTruckLoadWithTruckLoadItems = ({
    truckLoadId,
    truckLoadItems,
    removedItems,
  }: {
    truckLoadId: string;
    truckLoadItems: IGetContractItems[];
    removedItems: string[];
  }): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) => {
      const input: IUpdateTruckLoadWithTruckLoadItemsInput = {
        exclude_truck_load_items: removedItems,
        update_truck_load_items: truckLoadItems.map((item, index) => {
          const newItem = {
            id: item.extraItem ? undefined : item.id,
            allocated_amount: item.allocatedAmount,
            load_sort: index,
            extra_item: item.extraItem,
            contract_id: item.extraItem ? item.id : undefined,
          };
          return newItem;
        }),
      };

      client
        .mutation(TruckLoadQueries.updateTruckLoadWithTruckLoadItems(), {
          where: { truck_load_id: truckLoadId },
          input,
        })
        .then(({ updateTruckLoadWithTruckLoadItems }) => {
          resolve(updateTruckLoadWithTruckLoadItems);
        })
        .catch(err => {
          reject(err);
        });
    });

  static getTruckLoadAvailableDates = ({
    preScheduleId,
  }: {
    preScheduleId: string;
  }): Promise<moment.Moment[]> =>
    new Promise((resolve, reject) =>
      client
        .query(TruckLoadQueries.getCadenceByPreScheduleID(), {
          where: {
            pre_schedule_id: preScheduleId,
          },
        })
        .then(({ availableDates }) => {
          const formattedDates = availableDates.map(date =>
            moment(new Date(date).toISOString().substring(0, 10))
          );
          resolve(formattedDates);
        })
        .catch(err => reject(err))
    );

  static authorizeTruckLoad = ({
    truckLoadId,
  }: {
    truckLoadId: string;
  }): Promise<{ id: string; authorizedAt: string }> =>
    new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadQueries.authorizeTruckLoad(), {
          input: {
            truck_load_id: truckLoadId,
          },
        })
        .then(({ authorizeTruckLoad }) => {
          resolve(authorizeTruckLoad);
        })
        .catch(err => {
          reject(err);
        })
    );

  static synchronizeTruckLoad = ({
    truckLoadId,
  }: {
    truckLoadId: string;
  }): Promise<{ success: boolean }> =>
    new Promise((resolve, reject) =>
      client
        .mutation(TruckLoadQueries.synchronizeTruckLoad(), {
          input: {
            truck_load_id: truckLoadId,
          },
        })
        .then(({ synchronizeTruckLoad }) => {
          resolve(synchronizeTruckLoad);
        })
        .catch(err => {
          reject(err);
        })
    );

  static cancelTruckLoad = ({
    truck_load_id,
  }: {
    truck_load_id: string;
  }): Promise<boolean> =>
    new Promise((resolve, reject) => {
      client
        .mutation(TruckLoadQueries.cancelTruckLoad(), {
          where: { truck_load_id },
        })
        .then(({ cancelTruckLoad }) => {
          resolve(cancelTruckLoad.success);
        })
        .catch(err => {
          reject(err);
        });
    });

  static pdfDownload = ({
    truck_load_id,
  }: {
    truck_load_id: string;
  }): Promise<string> =>
    new Promise((resolve, reject) => {
      client
        .query(TruckLoadQueries.loadingOrderPdfDownlod(), {
          where: { truck_load_id },
        })
        .then(({ pdfDownload }) => {
          resolve(pdfDownload.pdfUrl);
        })
        .catch(err => {
          reject(err);
        });
    });

  static getPreScheduleByCreator = ({
    creatorId,
  }: {
    creatorId: string;
  }): Promise<Array<{ id: string; created_by: string }>> =>
    new Promise((resolve, reject) => {
      client
        .query(TruckLoadQueries.getPreScheduleByCreator(), {
          where: { creator_id: creatorId },
        })
        .then(({ getPreScheduleByCreator }) => {
          resolve(getPreScheduleByCreator);
        })
        .catch(err => {
          reject(err);
        });
    });

  static filterShippingCompanies = ({
    cnpj,
  }: {
    cnpj: string;
  }): Promise<IFilterShippingCompanies[]> =>
    new Promise((resolve, reject) => {
      client
        .query(TruckLoadQueries.filterShippingCompanies(), {
          where: { document_number_cnpj: cnpj },
        })
        .then(({ shippingCompanies }) => {
          resolve(shippingCompanies);
        })
        .catch(err => {
          reject(err);
        });
    });

  static findShippingCompany = ({
    shippingCompanyId,
  }: {
    shippingCompanyId: string;
  }): Promise<IFilterShippingCompanies[]> =>
    new Promise((resolve, reject) => {
      client
        .query(TruckLoadQueries.findShippingCompany(), {
          where: {
            shipping_company_id: shippingCompanyId,
          },
        })
        .then(({ shippingCompanies }) => {
          resolve(shippingCompanies);
        })
        .catch(err => {
          reject(err);
        });
    });

  static findDriver = ({
    documentNumberCpf,
  }: {
    documentNumberCpf: string;
  }): Promise<IDriverForm> =>
    new Promise((resolve, reject) => {
      client
        .query(TruckLoadQueries.findDriver(), {
          where: {
            document_number_cpf: documentNumberCpf,
          },
        })
        .then(({ driver }) => {
          resolve(driver);
        })
        .catch(err => {
          reject(err);
        });
    });
}

export default TruckLoadController;
