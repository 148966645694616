import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Statistic,
  Tooltip,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { BaseSelectRef } from 'rc-select';
import { DefaultRecordType } from 'rc-table/lib/interface';
import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { TFunction, Trans, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Normalizer from '../../../../classes/Normalizer';
import DraggingTable from '../../../../components/table/DraggingTable';
import UserContext from '../../../../contexts/user';
import { useRequest } from '../../../../hooks/useRequest';
import TruckLoadController from '../../../../structures/controllers/TruckLoad';
import UserController from '../../../../structures/controllers/User';
import { INewShippingCompany } from '../../../../structures/interfaces/Company/Company';
import {
  IGetContractItems,
  IStepOneData,
  IStepTwoData,
} from '../../../../structures/interfaces/TruckLoad';
import { IGetUserResponse } from '../../../../structures/interfaces/User';
import { scrollOptions } from '../../../../utils/formOptions';
import { IErrorMessage } from '../../../../utils/interfaces';
import AuthorizationCard from '../../components/AuthorizationCard';
import DriverFields from '../../components/DriverFields';
import TruckImage from '../../components/TruckImage';
import TruckLoadDescription from '../../components/TruckLoadDescription';
import TruckLoadFooter from '../../components/TruckLoadFooter';
import { PlusOutlined } from '@ant-design/icons';

function StepThree({
  t,
  stepOneData,
  stepTwoData,
  setStepTwoData,
  previous,
  setCreatedTruckLoadId,
  cancelPreSchedule,
  isCustomOrdering,
}: {
  t: TFunction;
  isCustomOrdering: boolean;
  stepOneData: IStepOneData;
  stepTwoData: IStepTwoData;
  setStepTwoData: (values: IStepTwoData) => void;
  previous: () => void;
  setCreatedTruckLoadId: (value: string) => void;
  cancelPreSchedule: (value: string, timeout?: boolean) => void;
}): ReactElement<unknown> {
  const history = useHistory();
  const {
    name,
    vehicle,
    vehicles,
    selectedDate,
    pickupLocation,
    cadence,
    seedType,
    cancel,
    unity,
  } = stepOneData;

  const { createdLoadColumns, totalWeight, colorsAssigned } = stepTwoData;

  const columnsWithoutRemove = createdLoadColumns.slice(0, -1);

  const [formObservation] = Form.useForm();
  const [formDriver] = Form.useForm();

  const baseString = 'pages.truckLoads.createLoad';
  const { userData, customerData } = useContext(UserContext);

  const [fetchShippingCompanies] = useRequest(
    TruckLoadController.getShippingCompanies
  );
  const [createTruckLoad, createTruckLoadRequesting] = useRequest(
    TruckLoadController.createTruckLoad
  );
  const [fetchUser] = useRequest(UserController.getUser);
  const [authorizeTruckLoad] = useRequest(
    TruckLoadController.authorizeTruckLoad
  );

  const [shippingCompanies, setShippingCompanies] = useState<
    INewShippingCompany[]
  >([]);
  const [openRegShippingCompany, setOpenRegShippingCompany] = useState(false);
  const [hasAdded, setHasAdded] = useState(false);
  const [informDriverLater, setInformDriverLater] = useState(false);
  const [hoveredBoxIndex, setHoveredBoxIndex] = useState<number>();
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number>();
  const [user, setUser] = useState<IGetUserResponse>();
  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false);
  const [receivedTruckLoadId, setReceivedTruckLoadId] = useState<string>('');

  const [dontHaveCarrier, setDontHaveCarrier] = useState(false);

  const [selectedDriverVehicle, setSelectedDriverVehicle] = useState<string>();
  const [isWrongVehicle, setIsWrongVehicle] = useState(false);

  const selectRef = useRef<BaseSelectRef | null>(null);

  useEffect(() => {
    const isDifferentVehicle =
      selectedDriverVehicle !== undefined &&
      selectedDriverVehicle !== stepOneData.vehicle.id;

    setIsWrongVehicle(isDifferentVehicle);
  }, [selectedDriverVehicle]);

  const isLoadOverVehicleCapacity = () => {
    const vehicleData = vehicles.find(
      vhcl => vhcl.id === selectedDriverVehicle
    );
    if (vehicleData) {
      return totalWeight > vehicleData.weightCapacity;
    }
    return false;
  };

  const fillDriverFields = () => {
    message.error({
      content: t(`${baseString}.messages.fillDriverFields`),
      className: 'custom-class',
      style: {
        marginTop: 60,
      },
    });
  };

  const showError = (error: IErrorMessage) => {
    message.error({
      content: t(error.message),
      style: { marginTop: '4rem' },
    });
  };

  useEffect(() => {
    if (userData) {
      fetchUser(userData.id)
        .then(setUser)
        .catch(err => showError(err));
    }
  }, [hasAdded]);

  const handleAuthorization = () => {
    authorizeTruckLoad({
      truckLoadId: receivedTruckLoadId,
    })
      .then(() => {
        setCreatedTruckLoadId(receivedTruckLoadId);
      })
      .catch(err => showError(err));
  };

  const handleAuthorizeLater = () => {
    setCreatedTruckLoadId(receivedTruckLoadId);
  };

  const handleFinish = () => {
    if (userData || customerData) {
      if (!informDriverLater) {
        formDriver
          .validateFields()
          .then(result => {
            result.shippingCompanyPhoneNumber = dontHaveCarrier
              ? null
              : result.shippingCompanyPhoneNumber.replace(/[^0-9]/g, '');

            result.shippingCompanyCnpj = dontHaveCarrier
              ? null
              : result.shippingCompanyCnpj.replace(/[^0-9]/g, '');

            const truckLoad = {
              organizationId:
                userData?.organization.id || stepOneData.organizationId,
              preScheduleId: cancel.id,
              name,
              loadNote: formObservation.getFieldValue('observation'),
              estimated_weight: totalWeight,
              items: stepTwoData.addedItems.map((item, index) => ({
                allocated_amount: item.allocatedAmount,
                contract_item_id: item.id,
                load_sort: index,
              })),
              driver: {
                name: result.driver,
                document_number_cpf: Normalizer.onlyNumbers(
                  result.driverDocument
                ),
                vehicle_license_plate: result.licensePlate,
                note: result.driverObservation,
                phone_number: Normalizer.onlyNumbers(result.phoneNumber),
                email: result.email,
                shipping_company_id: dontHaveCarrier
                  ? null
                  : result.shippingCompanyId,
              },
              referenceVehicle: vehicle.id,
              driverVehicle: result.vehicle.value,
              shipping_company_id: dontHaveCarrier
                ? null
                : result.shippingCompanyId,
              shipping_company_data: dontHaveCarrier
                ? null
                : {
                    company_name: result.shippingCompanyName,
                    trade_name: result.shippingCompanyTradeName,
                    document_number_cnpj: result.shippingCompanyCnpj,
                    phone_number: result.shippingCompanyPhoneNumber,
                    email: result.shippingCompanyEmail,
                  },
              isCustomOrdering,
              noShippingCompany: dontHaveCarrier,
            };

            createTruckLoad(truckLoad)
              .then(truckLoad => {
                setReceivedTruckLoadId(truckLoad.id);
                if (userData) {
                  setShowAuthorizationModal(true);
                } else {
                  setCreatedTruckLoadId(truckLoad.id);
                }
              })
              .catch(err => {
                showError(err.message);
              });
          })
          .catch(() => {
            fillDriverFields();
            selectRef.current?.focus();
          });
      } else {
        const truckLoad = {
          organizationId:
            userData?.organization.id || stepOneData.organizationId,
          preScheduleId: cancel.id,
          name,
          loadNote: formObservation.getFieldValue('observation'),
          estimated_weight: totalWeight,
          items: stepTwoData.addedItems.map((item, index) => ({
            allocated_amount: item.allocatedAmount,
            contract_item_id: item.id,
            load_sort: index,
          })),
          referenceVehicle: vehicle.id,
          isCustomOrdering,
          noShippingCompany: dontHaveCarrier,
        };

        createTruckLoad(truckLoad)
          .then(truckLoad => {
            setCreatedTruckLoadId(truckLoad.id);
          })
          .catch(err => {
            showError(err);
          });
      }
    }
  };

  const handleBoxHover = (index: number) => {
    if (index === -1) {
      setHoveredBoxIndex(undefined);
    } else {
      setHoveredBoxIndex(index);
    }
  };

  const handleRowMove = (newItemsOrder: IGetContractItems[]) => {
    setStepTwoData({
      ...stepTwoData,
      addedItems: newItemsOrder,
    });
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setInformDriverLater(e.target.checked);
    formDriver.resetFields();
  };

  const getDescriptions = () => ({
    name,
    vehicleModel: vehicle.vehicleModel,
    selectedDate,
    availableUnits: cadence.availableUnits,
    pickupLocation: pickupLocation?.title,
    seedType: seedType,
    unity: unity,
    vehicleBagsCapacity: vehicle.bagsCapacity,
    vehicleWeightCapacity: vehicle.weightCapacity,
    estimatedWeight: totalWeight,
  });

  useEffect(() => {
    if (
      selectedDriverVehicle !== undefined &&
      selectedDriverVehicle !== stepOneData.vehicle.id
    ) {
      setIsWrongVehicle(true);
    } else {
      setIsWrongVehicle(false);
    }
  }, [selectedDriverVehicle]);

  return (
    <>
      <Col span={24}>
        <Card
          title={t('pages.truckLoads.createLoad.cardTitles.descriptions')}
          bordered={false}
        >
          <TruckLoadDescription items={getDescriptions()} />
        </Card>
      </Col>
      {isCustomOrdering && (
        <>
          <Col span={24}>
            <Alert
              type="warning"
              message={<Trans i18nKey={`${baseString}.warnings.createdLoad`} />}
            />
          </Col>
          <Col span={24}>
            <Card title={t(`${baseString}.cardTitles.loads`)}>
              <Row>
                <Col span={3} xs={0} sm={0} md={0} lg={3}>
                  <TruckImage
                    colorsAssigned={colorsAssigned}
                    truckLoadItems={stepTwoData.addedItems}
                    onBoxHover={handleBoxHover}
                    hoveredRow={hoveredRowIndex}
                  />
                </Col>
                <Col span={21} xs={24} sm={24} md={24} lg={21}>
                  <DraggingTable
                    data={stepTwoData.addedItems}
                    setData={
                      handleRowMove as (values: DefaultRecordType[]) => void
                    }
                    columns={columnsWithoutRemove}
                    size="small"
                    tableProps={{
                      rowClassName: (record, index: number) => {
                        const reverseIndex =
                          stepTwoData.addedItems.length - index;
                        if (reverseIndex === hoveredBoxIndex)
                          return 'truck-load-item-row hovered-box';
                        return 'truck-load-item-row';
                      },
                    }}
                    onRowHover={(record, index) => {
                      if (index === -1) {
                        setHoveredRowIndex(undefined);
                      } else {
                        if (index !== undefined) {
                          const reverseIndex =
                            stepTwoData.addedItems.length - index - 1;
                          setHoveredRowIndex(reverseIndex);
                        }
                      }
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </>
      )}
      <Col span={24}>
        <Card
          title={t(`${baseString}.stepThree.cardTitles.observation`)}
          bordered={false}
        >
          <Row>
            <Col sm={24} md={24} lg={10} xl={8}>
              <Form
                scrollToFirstError={scrollOptions}
                form={formObservation}
                layout="vertical"
              >
                <Form.Item
                  name="observation"
                  label={t(`${baseString}.stepThree.labels.observation`)}
                >
                  <Input.TextArea
                    placeholder={t(
                      `${baseString}.stepThree.placeholders.observation`
                    )}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <Card
          title={t(`${baseString}.stepThree.cardTitles.driver`)}
          bordered={false}
          extra={
            !informDriverLater &&
            !dontHaveCarrier && (
              <Button
                onClick={() => {
                  setOpenRegShippingCompany(!openRegShippingCompany);
                }}
                type="primary"
              >
                <PlusOutlined /> Cadastrar nova transportada
              </Button>
            )
          }
        >
          <Checkbox
            checked={dontHaveCarrier}
            onChange={v => setDontHaveCarrier(v.target.checked)}
          >
            Não possuo transportadora
          </Checkbox>
          <Checkbox onChange={handleCheckboxChange}>
            {!dontHaveCarrier
              ? t(`${baseString}.stepThree.informDriverLater`)
              : 'Informar motorista depois'}
          </Checkbox>
          {!informDriverLater && (
            <Form form={formDriver} layout="vertical">
              <Row gutter={[24, 0]} style={{ marginTop: 24 }}>
                <DriverFields
                  openModal={openRegShippingCompany}
                  setOpenModal={setOpenRegShippingCompany}
                  form={formDriver}
                  hasAdded={hasAdded}
                  setHasAdded={setHasAdded}
                  shippingCompanies={shippingCompanies}
                  vehicles={vehicles}
                  setSelectedDriverVehicle={setSelectedDriverVehicle}
                  selectRef={selectRef}
                  stepOneData={stepOneData}
                  setIsWrongVehicle={setIsWrongVehicle}
                  dontHaveCarrier={dontHaveCarrier}
                />
              </Row>
              {isWrongVehicle && (
                <Alert
                  type="warning"
                  message={t(`${baseString}.stepThree.wrongVehicleWarning`)}
                />
              )}
            </Form>
          )}
        </Card>
      </Col>
      <TruckLoadFooter>
        <Statistic.Countdown
          title={t(`${baseString}.footer.countdown`)}
          value={cancel.expiresIn}
          style={{ marginTop: '12px' }}
          valueStyle={{ lineHeight: '20px' }}
          onFinish={() => {
            cancelPreSchedule(cancel.id, true);
          }}
        />
        <Button
          style={{ margin: '24px 12px 24px 24px' }}
          danger
          htmlType="reset"
          onClick={() => {
            history.push('/cargas');
          }}
        >
          {t(`${baseString}.footer.cancelBtn`)}
        </Button>
        <Button
          style={{ margin: '24px 12px' }}
          htmlType="reset"
          onClick={() => {
            previous();
          }}
        >
          {t(`${baseString}.footer.backBtn`)}
        </Button>
        <Button
          style={{ margin: '24px 12px' }}
          type="primary"
          htmlType="submit"
          onClick={handleFinish}
          loading={createTruckLoadRequesting}
          disabled={isLoadOverVehicleCapacity()}
        >
          <Tooltip
            open={isLoadOverVehicleCapacity()}
            title="Carga total acima da capacidade do veículo" // Implementar i18n
            getPopupContainer={triggerNode =>
              triggerNode.parentNode as HTMLElement
            }
            overlayStyle={{
              maxWidth: 200,
            }}
            overlayInnerStyle={{
              whiteSpace: 'break-spaces',
              textAlign: 'center',
            }}
          >
            {t(`${baseString}.footer.finishBtn`)}
          </Tooltip>
        </Button>
      </TruckLoadFooter>
      {user && (
        <Modal
          title={t('pages.truckLoads.viewLoad.cardTitles.authorize')}
          open={showAuthorizationModal}
          footer={null}
        >
          <AuthorizationCard
            handleAuthorization={handleAuthorization}
            handleAuthorizeLater={handleAuthorizeLater}
          />
        </Modal>
      )}
    </>
  );
}

export default withTranslation()(StepThree);
