import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  CardProps,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import TruckLoadController from '../../structures/controllers/TruckLoad';
import {
  IGetTruckLoadsResponse,
  IGetTruckLoadWhere,
} from '../../structures/interfaces/TruckLoad';
import { IGetLoginDataUser } from '../../structures/interfaces/User';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { IGetCustomer } from '../../structures/interfaces/Clients';
import { IUseRequestAction } from '../../hooks/useRequest';
import moment from 'moment';

interface ITruckLoadsFilterProps {
  cardProps: CardProps;
  options: DefaultOptionType[];
  truckLoads: IGetTruckLoadsResponse[];
  setTruckLoads: Dispatch<SetStateAction<IGetTruckLoadsResponse[]>>;
  userData?: IGetLoginDataUser | null;
  customerData?: IGetCustomer | null;
  fetchLoads: IUseRequestAction<IGetTruckLoadWhere, IGetTruckLoadsResponse[]>;
  isLoadingLoads: boolean;
}

const TruckLoadsFilter = ({
  cardProps,
  options,
  truckLoads,
  userData,
  customerData,
  setTruckLoads,
  fetchLoads,
}: ITruckLoadsFilterProps): ReactElement => {
  const [form] = Form.useForm();

  const onFinish = ({
    search = '',
    status: truckLoadStatus,
    contractDate: truckLoadDate,
    salesmen,
    truckLoadPickUpLocation,
  }: {
    search?: string;
    status?: string;
    contractDate?: [moment.Moment, moment.Moment];
    salesmen?: string;
    truckLoadPickUpLocation?: string;
  }) => {
    if (truckLoads) {
      if (userData) {
        let salesmanFilters: IGetTruckLoadWhere = {
          organization_id: userData.organization.id,
          subordinates_filter: null,
        };
        if (salesmen === 'subordinatesTruckLoads') {
          salesmanFilters = {
            organization_id: userData.organization.id,
            subordinates_filter: {
              subordinates_only: true,
            },
          };
        } else if (salesmen === 'myTruckLoads') {
          salesmanFilters = {
            organization_id: userData.organization.id,
            subordinates_filter: {
              ignore_subordinates: true,
            },
          };
        }
        fetchLoads(salesmanFilters).then(truckLoads => {
          const sorted = truckLoads.sort((a, b) => {
            return moment(b.createdAt).unix() - moment(a.createdAt).unix();
          });

          const filters = TruckLoadController.filterTruckLoad;
          setTruckLoads(() =>
            filters(sorted, {
              search,
              truckLoadStatus,
              truckLoadDate,
              truckLoadPickUpLocation,
            })
          );
        });
      } else if (customerData) {
        const filters = TruckLoadController.filterTruckLoad;
        setTruckLoads(() =>
          filters(truckLoads, {
            search,
            truckLoadStatus,
            truckLoadDate,
          })
        );
      }
    }
  };

  return (
    <Card {...cardProps}>
      <Form size="small" layout="vertical" onFinish={onFinish} form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item name={'search'} label={'Pesquisa'}>
              <Input.Search
                placeholder="Procure itens na tabela"
                enterButton
                allowClear
                onSearch={() => form.submit()}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item name={'status'} label={'Status'}>
              <Select placeholder="Selecione" options={options} allowClear />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item name={'contractDate'} label={'Data do contrato'}>
              <DatePicker.RangePicker style={{ width: '100%' }} allowClear />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item name={'salesmen'} label={'Vendedores'}>
              <Select placeholder="Selecione" allowClear />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Space size={12}>
              <Button
                icon={<ClearOutlined />}
                onClick={() => form.resetFields()}
              >
                Limpar filtro
              </Button>
              <Button
                icon={<FilterOutlined />}
                type="primary"
                onClick={() => form.submit()}
              >
                Filtrar
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default TruckLoadsFilter;
